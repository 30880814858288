import React from 'react'
import { DetailStyle } from './styled'

export default function Index() {
    return (
        <DetailStyle>
            DetailStyle
        </DetailStyle>
    )
}
